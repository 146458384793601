import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Layout, Modal } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const { Content } = Layout;
const url_base = process.env.REACT_APP_URL_BACKEND;

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [token, setToken] = useState(null);

    useEffect(() => {
        const keyFromURL = searchParams.get("key");
        if (!keyFromURL) {
            Modal.error({
                title: 'Erro',
                content: 'Token inválido ou ausente.',
                onOk: () => navigate('/login'),
            });
        } else {
            setToken(keyFromURL);
        }
    }, [searchParams, navigate]);

    const handleResetPassword = async (values) => {
        if (values.newPassword !== values.confirmPassword) {
            Modal.error({
                title: 'Erro',
                content: 'As senhas não coincidem.',
            });
            return;
        }

        setLoading(true);
        try {
            await axios.post(`${url_base}/api/account/reset-password/finish`, {
                key: token,
                newPassword: values.newPassword
            });

            Modal.success({
                title: 'Senha redefinida com sucesso!',
                content: 'Agora você pode fazer login com sua nova senha.',
                onOk: () => navigate('/login'),
            });
        } catch (error) {
            console.error('Erro ao redefinir senha:', error);
            Modal.error({
                title: 'Erro',
                content: 'Ocorreu um erro ao redefinir a senha. Tente novamente.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Content style={{ width: '100%', maxWidth: '400px', padding: '20px' }}>
                <Title level={2} style={{ textAlign: 'center' }}>Redefinir Senha</Title>
                <Text type="secondary" style={{ display: 'block', textAlign: 'center', marginBottom: '20px', fontSize: '16px' }}>
                    Digite sua nova senha abaixo.
                </Text>

                <Form
                    layout="vertical"
                    name="reset-password-form"
                    onFinish={handleResetPassword}
                >
                    <Form.Item
                        label="Nova Senha"
                        name="newPassword"
                        rules={[
                            { required: true, message: 'Por favor, digite sua senha!' },
                            { min: 8, message: 'A senha deve ter pelo menos 8 caracteres.' },
                            {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                message: 'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.',
                            }
                        ]}
                        tooltip={{ title: 'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.', icon: <InfoCircleOutlined /> }}
                    >
                        <Input.Password placeholder="Nova Senha" style={{ height: '45px' }} />
                    </Form.Item>

                    <Form.Item
                        label="Confirme a Senha"
                        name="confirmPassword"
                        rules={[
                            { required: true, message: 'Por favor, confirme sua senha!' },
                            { min: 8, message: 'A senha deve ter pelo menos 8 caracteres.' },
                            {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                message: 'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.',
                            }
                        ]}
                        tooltip={{ title: 'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.', icon: <InfoCircleOutlined /> }}
                    >
                        <Input.Password placeholder="Confirme a Senha" style={{ height: '45px' }} />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            style={{ height: '45px', backgroundColor: '#FB8500' }}
                            loading={loading}
                        >
                            Redefinir Senha
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
};

export default ResetPassword;
