import React, { useState, useEffect } from "react";
import { Table, Input, Space, Button, Modal, Dropdown, Menu, Form, message, Select, Row, Col } from "antd";
import axios from "axios";
import { useAuth } from "../../hooks/useAuth";
import {
    PencilSimpleLine,
    MapTrifold
} from "@phosphor-icons/react"
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';
import * as DataServices from '../../dataservices/Services'
const { Search } = Input;
const { Option } = Select;

const url_base = process.env.REACT_APP_URL_BACKEND;
const apiKey =  process.env.REACT_APP_API_KEY_MAPS;

const AneelDashboardComponent = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 5, total: 0 });
    const [searchText, setSearchText] = useState("");
    const [isMapModalVisible, setIsMapModalVisible] = useState(false); 
    const [isSiteModalVisible, setIsSiteModalVisible] = useState(false); 
    const [selectedLocation, setSelectedLocation] = useState({ latitude: null, longitude: null });
    const { user } = useAuth();
    const token = localStorage.getItem('token');
    const [ selectedSite, setSelectedSite ] = useState(false);
    const [form] = Form.useForm();
    const [companies, setCompanies] = useState([]);

    const roles = user?.role.split(',').map(role => role.trim());
    const isAdmin =roles?.includes("ROLE_ADMIN") || roles?.includes("ROLE_DEV");

    const resetForm = () => {
        form.resetFields();
    };

    const usinaMap = {
        UFV: "usina-solars",
        EOL: "usina-eolicas",
        UTE: "usina-termeletricas",
        UTN: "usina-termonuclears",
        CGH: "central-geradora-hidroeletricas",
        UHE: "usina-hidreletricas",
        PCH: "pequena-central-hidreletricas",
        UBC: "usina-biocombustivels",
    };
    
    const getUsinaUrlBySigUF = (sigUFPrincipal) => {
        const suffix = usinaMap[sigUFPrincipal];
        if (!suffix) {
            message.error(`SigUFPrincipal inválido: ${sigUFPrincipal}. Favor contactar o suporte.`);        }
        return `${process.env.REACT_APP_URL_BACKEND}/api/${suffix}`;
    };

    async function getAddress(lat, lng) {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Erro na API: ${response.status}`);
            }
            const data = await response.json();
            if (data.status !== "OK") {
                throw new Error(`Erro na resposta da API: ${data.error_message || data.status}`);
            }
    
            const getComponent = (type, data) => {
                const component = data.find(c => c.types.includes(type));
                return component;
            };

            const addressComponents_2 = data.results[0]?.address_components || [];
            const addressComponents_1 = data.results[1]?.address_components || [];
            


            const cep = getComponent("postal_code", addressComponents_1) || getComponent("postal_code", addressComponents_2)                      
            const numero =  getComponent("street_number", addressComponents_1) || getComponent("street_number", addressComponents_2)
            const rua =  getComponent("route", addressComponents_1) || getComponent("route", addressComponents_2)
            const bairro =  getComponent("sublocality", addressComponents_1) || getComponent("sublocality", addressComponents_2)
            const cidade =  getComponent("administrative_area_level_2", addressComponents_1) || getComponent("administrative_area_level_2", addressComponents_2)
            const estado =  getComponent("administrative_area_level_1", addressComponents_1) || getComponent("administrative_area_level_1", addressComponents_2)
            const pais =  getComponent("country", addressComponents_1) || getComponent("country", addressComponents_2)
            
            
            return {
                cep,
                numero,
                rua,
                bairro,
                cidade,
                estado,
                pais,
            };

        } catch (error) {
            console.error(`Erro ao buscar o endereço: ${error.message}`);
            return {
                formattedAddress: "Erro ao obter o endereço",
                cep: "",
                rua: "",
                bairro: "",
                cidade: "",
                estado: "",
                pais: ""
            };
        }
    }
    
      
    const fetchCompanies = async () => {
        try {
            const data = await DataServices.fetchCompanies();
            
            setCompanies(data);
        } catch (error) {
            message.error('Erro ao carregar empresas cadastradas.');
        }
    };      

    const handleSave = async () => {
        try {
            let selectedCompany;
            setLoading(true);
            try {
                setLoading(true);
                await form.validateFields();
                const values = form.getFieldsValue();
        
                await fetchCompanies();
                selectedCompany = companies.find(c => c.cnpj === values.responsavel);
        
                if (selectedCompany !== null) {
                    if (!values.nome || !values.responsavel) {
                        message.error('Erro: Informe os dados da empresa antes de continuar.');
                        setLoading(false);
                        return;
                    }
        
                    // Criar nova empresa
                    const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/empresa-clientes`, {
                        nome: values.nome,
                        cnpj: values.responsavel,
                    }, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    selectedCompany = response.data;
                    message.success('Empresa cadastrada com sucesso!');
                }
        
                
            } catch (error) {
                message.error('Erro ao cadastrar ou selecionar a empresa.');
            } finally {
                setLoading(false);
            }

            await form.validateFields();
            const values = form.getFieldsValue();
            // Função para converter strings com vírgula para número decimal
            const parseToDouble = (value) => {
                if (typeof value === 'string') {
                    return parseFloat(value.replace(',', '.')) || 0;
                }
                return value;
            };
    
            // Criar payload do site
            const payloadSite = {
                uuid: uuidv4(),
                nome: values.nome,
                pessoaResponsavel: values.responsavel,
                celular: values.telefone,
                cargo: values.cargo,
                cep: values.cep,
                emailResponsavel: values.email,
                numero: parseInt(values.numero, 10),
                logradouro: values.rua,
                bairro: values.bairro,
                cidade: values.cidade,
                estado: values.estado,
                latitude: parseToDouble(selectedSite?.NumCoordNEmpreendimento) || 0,
                longitude: parseToDouble(selectedSite?.NumCoordEEmpreendimento) || 0,
                telefone: values.telefone,
                status: selectedSite?.DscFaseUsina === "Operação" ? "Conectado" : "Desconectado",
                metadata: JSON.stringify(selectedSite),
                empresaCliente: selectedCompany
            };
    
            // Salvar o site
            const siteResponse = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/sites`, payloadSite, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (siteResponse.status === 201) {
                message.success("Site salvo com sucesso!");
    
                // Adicionar delay para evitar problemas de referência
                await new Promise((resolve) => setTimeout(resolve, 2000));
    
                const siteData = siteResponse.data;
    
                try {
                    // Criar payload da usina
                    const payloadUsina = {
                        bairro: values.bairro,
                        cep: values.cep,
                        cidade: values.cidade,
                        emailResponsavel: null,
                        estado: values.estado,
                        inversor: null,
                        kilowattPico: parseToDouble(values.kwpTotal),
                        latitude: parseToDouble(selectedSite?.NumCoordNEmpreendimento) || 0,
                        longitude: parseToDouble(selectedSite?.NumCoordEEmpreendimento) || 0,
                        logradouro: values.rua,
                        mesmoEnderecoSite: true,
                        nome: selectedSite.NomEmpreendimento,
                        numero: parseInt(values.numeroUsina, 10) || 0,
                        numeroModulos: parseInt(values.modulosUsina, 10) || 0,
                        pessoaResponsavel: values.NumCPFCNPJ,
                        potenciaInversor: null,
                        potenciaModulo: null,
                        protocoloComunicacao: null,
                        site: siteData, // Vincula o site salvo à usina
                        tipoInversor: null,
                        uuid: uuidv4(),
                        kilowattPico: parseToDouble(selectedSite?.MdaPotenciaOutorgadaKw) || 0,
                        mdaPotenciaOutorgadaKw: parseToDouble(selectedSite?.MdaPotenciaOutorgadaKw) || 0,
                        mdaGarantiaFisicaKw: parseToDouble(selectedSite?.MdaGarantiaFisicaKw) || 0,
                        mdaPotenciaFiscalizadaKw: parseToDouble(selectedSite?.MdaPotenciaFiscalizadaKw) || 0,
                        idcGeracaoQualificada: selectedSite?.IdcGeracaoQualificada,
                        dscPropriRegimePariticipacao: selectedSite?.DscPropriRegimePariticipacao,
                        datEntradaOperacao: selectedSite?.DatEntradaOperacao,
                    };
    
                    // Salvar a usina
                    const urlUsinas = getUsinaUrlBySigUF(selectedSite.SigTipoGeracao);
                    const usinaResponse = await axios.post(urlUsinas, payloadUsina, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
    
                    if (usinaResponse.status === 201) {
                        message.success("Usina Solar salva com sucesso!");
                    } else {
                        throw new Error("Falha ao salvar a Usina Solar.");
                    }
                } catch (usinaError) {
                    // Deletar o site em caso de erro no cadastro da usina
                    
                    await axios.delete(`${process.env.REACT_APP_URL_BACKEND}/api/sites/${siteData.id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    message.error("Erro ao salvar a Usina Solar. O site foi deletado.");
                    throw new Error(usinaError.message || "Falha ao salvar a Usina Solar.");
                }
            } else {
                throw new Error("Falha ao salvar o Site. Operação cancelada.");
            }
    
            // Fechar modal e limpar estado
            setIsSiteModalVisible(false);
            setSelectedLocation({ latitude: null, longitude: null });
        } catch (error) {
            // message.error(error.message || "Erro ao salvar.");
        } finally {
            setLoading(false);
        }
    };
    
    


    const fetchData = async (params = {}) => {
        setLoading(true);
        try {
            const backendEndpoint = `${url_base}/api/combined-data`;
            const { current, pageSize } = params.pagination || pagination;
    
            const response = await axios.get(backendEndpoint, {
                params: {
                    offset: (current - 1) * pageSize,
                    limit: pageSize,
                    q: params.search || searchText,
                },
                headers: { Authorization: `Bearer ${token}` }
            });
        
            if (response?.data?.success) {
                const combinedData = response.data.data || [];
                const allKeys = combinedData.length ? Object.keys(combinedData[0]) : [];
    
                // Criação de colunas dinâmicas
                const dynamicColumns = allKeys.map((key) => ({
                    title: key,
                    dataIndex: key,
                    key: key,
                }));
    
                // Adiciona coluna de ações
                dynamicColumns.push({
                    title: "Ações",
                    key: "actions",
                    render: (_, record) => (
                        <Dropdown overlay={getMenu(record)} trigger={["click"]}>
                            <Button type="text" icon={<EllipsisOutlined />} />
                        </Dropdown>
                    ),
                });
    
                // Atualiza estado do frontend
                setColumns(dynamicColumns);
                setData(combinedData);
                setPagination({
                    ...pagination,
                    current,
                    total: response.data.total || 0,
                });
            } else {
                console.error("Erro na resposta do backend:", response?.data?.message || "Formato inesperado.");
            }
        } catch (error) {
            // Tratamento de erros detalhado
            if (error.response) {
                console.error("Erro ao fazer requisição ao backend:", error.response.data);
            } else if (error.request) {
                console.error("Nenhuma resposta recebida do backend:", error.request);
            } else {
                console.error("Erro ao configurar a requisição:", error.message);
            }
        } finally {
            setLoading(false);
        }
    };
    

    const handleSearch = (value) => {
        setSearchText(value);
        fetchData({ search: value, pagination: { ...pagination, current: 1 } });
    };

    const handleTableChange = (pagination) => {
        fetchData({ pagination, search: searchText });
    };

    const handleOpenMap = (latitude, longitude) => {
        setSelectedLocation({ latitude: latitude.replace(",", "."), longitude: longitude.replace(",", ".") });
        setIsMapModalVisible(true);
    };

    const handleCloseMap = () => {
        setIsMapModalVisible(false);
        setSelectedLocation({ latitude: null, longitude: null });
    };

    const handleCadastrar = async (record) => {
        setSelectedSite(record); 
    
        try {
            const addressInfos = await getAddress(
                record.NumCoordNEmpreendimento.replace(',', '.'),
                record.NumCoordEEmpreendimento.replace(',', '.')
            );

            form.setFieldsValue({
                nome: record.NomAgente || "", 
                responsavel: record.NumCPFCNPJ || "",
                telefone: record.celular || "",
                email: record.emailResponsavel || "",
                cargo: record.cargo || "",
                cep: addressInfos.cep ? addressInfos.cep?.long_name :"",
                rua: addressInfos.rua ? addressInfos.rua?.long_name :"",
                bairro: addressInfos.bairro ? addressInfos.bairro?.long_name :"",
                cidade: addressInfos.cidade ? addressInfos.cidade?.long_name :"",
                estado: addressInfos.estado ? addressInfos.estado?.long_name :"",
                numero: addressInfos.numero ? addressInfos.numero?.long_name :"",
                potenciaOutorga: record.MdaPotenciaOutorgadaKw,
                kwpTotal: record.MdaPotenciaOutorgadaKw
            });
    
            setSelectedLocation(record.NumCoordNEmpreendimento, record.NumCoordEEmpreendimento);
            setIsSiteModalVisible(true);
        } catch (error) {
            message.error('Erro ao se comunicar com Aneel.');
        }

       
    };
    
    
    

    const getMenu = (record) => (
        <Menu>
            <Menu.Item key="view-map" onClick={() => handleOpenMap(record.NumCoordNEmpreendimento, record.NumCoordEEmpreendimento)}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}>
                    <MapTrifold />
                    <span>Ver Mapa</span>
                </div>
            </Menu.Item>
            {isAdmin && (
                <Menu.Item key="cadastrar" onClick={() => handleCadastrar(record)}> {/* Corrigido aqui */}
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}>
                        <PencilSimpleLine />
                        <span>Cadastrar</span>
                    </div>
                </Menu.Item>
            )}
        </Menu>
    );
    

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {isAdmin && (
                <>
                    <h2 style={{ color: "#2957A4", marginTop: 20}}>Dados das APIs | SIGA e Agentes de Geração de Energia Elétrica</h2>
                    <div style={{  float: 'right' }}>                        
                        <Search
                            placeholder="Pesquisar na tabela..."
                            allowClear
                            enterButton="Filtrar"
                            // suffix={<SearchOutlined/>}
                            onSearch={handleSearch}
                            value={searchText}
                            style={{ marginBottom: '16px', width: '300px' }}

                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>

                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        rowKey={(record) => record.CodCEG || record._id}
                        pagination={pagination}
                        onChange={handleTableChange}
                        scroll={{ x: "max-content" }}
                    />
                    <Modal
                        title="Localização no Mapa"
                        visible={isMapModalVisible}
                        onCancel={handleCloseMap}
                        footer={null}
                        width={800}
                    >
                        {selectedLocation?.latitude && selectedLocation?.longitude ? (
                            <iframe
                                title="Mapa"
                                width="100%"
                                height="500"
                                style={{ border: 0}}
                                loading="lazy"
                                allowFullScreen
                                src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${
                                    selectedLocation.latitude},${selectedLocation.longitude}&maptype=satellite&zoom=18`}
                            />
                        ) : (
                            <p>Coordenadas não disponíveis.</p>
                        )}
                    </Modal>
                    <Modal
                        visible={isSiteModalVisible}
                        onCancel={() => {
                            resetForm();
                            setIsSiteModalVisible(false);
                        }}
                        footer={[
                            <Button key="cancel" onClick={() => setIsSiteModalVisible(false)}>
                                Cancelar
                            </Button>,
                            <Button key="save" type="primary" onClick={handleSave} loading={loading}>
                                Salvar
                            </Button>,
                        ]}
                        width={"80%"}
                    >
                        <h2 style={{ color: "#2957A4" }}>Cadastro de Site</h2>
                        
                        <Form layout="vertical" form={form}>
                            <Row gutter={12} align="bottom">
                                <Col span={4}>
                                    <Form.Item
                                        label="Nome do Site"
                                        name="nome"
                                        rules={[{ required: true, message: 'Informe o nome do projeto!' }]}
                                    >
                                        <Input placeholder="Nome do Projeto" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="CNPJ do Responsável"
                                        name="responsavel"
                                        rules={[{ required: true, message: 'Informe o responsável!' }]}
                                    >
                                        <Input placeholder="Digite o Nome" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Telefone ou Celular"
                                        name="telefone"
                                        rules={[{ required: true, message: 'Informe o telefone!' }]}
                                    >
                                        <Input placeholder="Digite o Número" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[{ required: true, message: 'Informe o email!' }]}
                                    >
                                        <Input placeholder="Digite o Número" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Cargo do Responsável"
                                        name="cargo"
                                        rules={[{ required: true, message: 'Escolha o cargo!' }]}
                                    >
                                        <Select placeholder="Escolha o Cargo">
                                            <Option value="Gerente">Gerente</Option>
                                            <Option value="Supervisor">Supervisor</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <h2 style={{ color: "#2957A4" }}>Endereço do Site</h2>

                            <Row gutter={12} align="bottom">
                                <Col span={4}>
                                    <Form.Item
                                        label="CEP"
                                        name="cep"
                                        rules={[{ required: true, message: 'Informe o CEP!' }]}
                                    >
                                        <Input placeholder="Digite o CEP" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Rua"
                                        name="rua"
                                        rules={[{ required: true, message: 'Informe a rua!' }]}
                                    >
                                        <Input placeholder="Nome da Rua" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Bairro"
                                        name="bairro"
                                        rules={[{ required: true, message: 'Informe o bairro!' }]}
                                    >
                                        <Input placeholder="Nome do Bairro" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Cidade"
                                        name="cidade"
                                        rules={[{ required: true, message: 'Informe a cidade!' }]}
                                    >
                                        <Input placeholder="Nome da Cidade" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Estado"
                                        name="estado"
                                        rules={[{ required: true, message: 'Informe a estado!' }]}
                                    >
                                        <Input placeholder="Nome da Cidade" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="Complemento" name="complemento">
                                        <Input placeholder="Digite o Complemento" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Nº"
                                        name="numero"
                                        rules={[{ required: true, message: 'Informe o número!' }]}
                                    >
                                        <Input placeholder="Digite o Nº" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Status do Site"
                                        name="status"
                                        rules={[{ required: true, message: 'Selecione o atual status do site.' }]}
                                    >
                                        <Select placeholder="Selecione o status">
                                            <Option value="Conectado">Conectado</Option>
                                            <Option value="Desconectado">Desconectado</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </Row>

                            <h2 style={{color: '#2957A4'}}>Informações da Usina</h2>

                            <Row gutter={12} align="bottom">
                                <Col span={4}>
                                    <Form.Item
                                        label="kWp Total"
                                        name="kwpTotal"
                                        rules={[{ required: true, message: 'Informe a potência total!' }]}
                                    >
                                        <Input placeholder="kWp Total"/>
                                    </Form.Item>
                                </Col>                                
                                <Col span={4}>
                                    <Form.Item
                                        label="Potência Outorgada"
                                        name="potenciaOutorga"
                                        rules={[{ required: true, message: 'Informe a potência de !' }]}
                                    >
                                        <Input placeholder="Potência Outorgada" />
                                    </Form.Item>
                                </Col>
                            </Row>


                            
                        </Form>
                    </Modal>
                </>
            )}
        </Space>
    );
};

export default AneelDashboardComponent;
