import React, { useEffect, useState } from 'react';
import { Layout, Form, Input, Select, Row, Col, Button, message } from 'antd';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

const Carregador = ({
    form,
    siteData,
    currentStep,
    totalSteps,
    onPrev,
    onNext,
    token,
}) => {
    const [loading, setLoading] = useState(false);
    const [isCarregadorSaved, setIsCarregadorSaved] = useState(false); // Estado para verificar se foi salvo

    useEffect(() => {
        console.log("Step Atualizado:", currentStep);
    }, [currentStep]);

    const validateSiteData = () => {
        if (!siteData || Object.keys(siteData).length === 0) {
            message.error('As informações do site são obrigatórias para salvar o Carregador.');
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        message.info("Endpoint em desenvolvimento, tente novamente mais tarde!")
    
        // try {
        //     if (!validateSiteData()) return;

        //     await form.validateFields();
        //     setLoading(true);

        //     const values = form.getFieldsValue();
        //     const payload = {
        //         uuid: uuidv4(),
        //         site: siteData, // Site associado
        //         quantidadeCarregadores: values.quantidadeCarregadores,
        //         numeroSerie: values.numeroSerie,
        //         potencia: values.potencia,
        //     };

        //     await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/carregadores`, payload, {
        //         headers: { Authorization: `Bearer ${token}` },
        //     });

        //     message.success('Carregador salvo com sucesso!');
        //     setIsCarregadorSaved(true); // Atualiza estado para permitir avançar
        // } catch (error) {
        //     message.error(error.message || 'Erro ao salvar o Carregador.');
        // } finally {
        //     setLoading(false);
        // }
    };

    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>
            <Form layout="vertical" form={form}>
                <h2 style={{ color: "#2957A4" }}>Carregador</h2>
                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="Quantidade de Carregadores"
                            name="quantidadeCarregadores"
                            rules={[{ required: true, message: 'Informe a quantidade!' }]}
                        >
                            <Input placeholder="Digite a quantidade de carregadores" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Nº de Série do Carregador"
                            name="numeroSerie"
                            rules={[{ required: true, message: 'Informe o número de série!' }]}
                        >
                            <Input placeholder="Digite o Número" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Escolha a Potência"
                            name="potencia"
                            rules={[{ required: true, message: 'Escolha a potência!' }]}
                        >
                            <Select placeholder="Escolha a Potência">
                                <Option value="7.4kW">7.4 kW</Option>
                                <Option value="11kW">11 kW</Option>
                                <Option value="22kW">22 kW</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', gap: 8 }}>
                            {currentStep > 0 && (
                                <Button onClick={onPrev} disabled={loading}>
                                    Voltar
                                </Button>
                            )}
                            
                            <Button type="primary" onClick={handleSave} loading={loading}>
                                Salvar
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Layout>
    );
};

export default Carregador;
