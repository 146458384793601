import React, { useEffect, useState } from 'react';
import { Layout, Form, Input, Row, Col, Button, Radio, message, Modal, Tag, Popconfirm, Select } from 'antd';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

const Bateria = ({
    form,
    siteData,
    currentStep,
    totalSteps,
    onPrev,
    onNext,
    token,
    onSave,
    handleCepChange,
    handleNumeroChange,
}) => {
    const [loading, setLoading] = useState(false);
    const [isBateriaSaved, setIsBateriaSaved] = useState(false);
    const [baterias, setBaterias] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentBateria, setCurrentBateria] = useState(null);
    const [modalForm] = Form.useForm();
    const [useSameAddress, setUseSameAddress] = useState(true);

    
    const handleSaveForm = () => form.getFieldsValue();
    useEffect(() => {
        if (onSave) {
            onSave(handleSaveForm);
        }
    }, [onSave]);

    
    useEffect(() => {
        if (useSameAddress && siteData) {
            form.setFieldsValue({
                cep: siteData.cep || '',
                logradouro: siteData.logradouro || '',
                bairro: siteData.bairro || '',
                cidade: siteData.cidade || '',
                estado: siteData.estado || '',
                numero: siteData.numero || '',
              });
              
        }
    }, [useSameAddress, siteData, form]);

    const validateSiteData = () => {
        if (!siteData || Object.keys(siteData).length === 0) {
            message.error('As informações do site são obrigatórias para salvar a Bateria.');
            return false;
        }
        return true;
    };

    
    const showModal = (bateria = null) => {
        setCurrentBateria(bateria);
        if (bateria) {
            modalForm.setFieldsValue(bateria);
        } else {
            modalForm.resetFields();
        }
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setCurrentBateria(null);
        modalForm.resetFields();
    };

    
    const handleSaveBateria = async () => {
        try {
            if (!validateSiteData()) return;
            const values = await modalForm.validateFields();

            
            const mainValues = form.getFieldsValue();
            
            
            const payload = {
                uuid: currentBateria?.uuid || uuidv4(),
                nome: values.nomeBateria, 
                bateria: {
                    uuid: currentBateria?.uuid || uuidv4(),
                    nome: values.nomeBateria,
                    pessoaResponsavel: mainValues.nomeGerente, 
                    emailResponsavel: mainValues.emailGerente,
                    mesmoEnderecoSite: useSameAddress,
                    cep: useSameAddress ? siteData.cep : mainValues.cep,
                    numero: useSameAddress ? siteData.numero : mainValues.numero,
                    logradouro: useSameAddress ? siteData.logradouro : mainValues.logradouro,
                    bairro: useSameAddress ? siteData.bairro : mainValues.bairro,
                    cidade: useSameAddress ? siteData.cidade : mainValues.cidade,
                    estado: useSameAddress ? siteData.estado : mainValues.estado,
                    latitude: useSameAddress ? siteData.latitude : mainValues.latitude,
                    longitude: useSameAddress ? siteData.longitude : mainValues.longitude,
                    fabricante: values.fabricante,
                    modelo: values.modelo,
                    tipoEquipamento: values.tipoEquipamento,
                    capacidade: parseFloat(values.capacidade),
                    taxaDescarga: parseFloat(values.taxaDescarga),
                    densidadeEnergetica: parseFloat(values.densidadeEnergetica),
                    tensao: parseFloat(values.tensao),
                    eficienciaEnergitica: parseFloat(values.eficienciaEnergetica),
                    tempoRecarga: parseFloat(values.tempoRecarga),
                    protocoloComunicacao: values.protocoloComunicacao || 'Não informado',
                    site: siteData
                },
            };

            const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/baterias`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const bateriaSalva = { ...payload, id: response.data.id };

            const updatedBaterias = currentBateria
                ? baterias.map((bat) => (bat.uuid === currentBateria.uuid ? bateriaSalva : bat))
                : [...baterias, bateriaSalva];

            setBaterias(updatedBaterias);
            message.success(currentBateria ? 'Bateria atualizada!' : 'Bateria adicionada!');
            setIsBateriaSaved(true);
            handleCloseModal();
        } catch (error) {
            console.error('Erro ao salvar a bateria:', error);
            const errorMessage = error.response?.data?.message || 'Erro ao salvar a bateria. Verifique os dados e tente novamente.';
            message.error(errorMessage);
        }
    };

    const handleDeleteBateria = (uuid) => {
        const updatedBaterias = baterias.filter((bat) => bat.uuid !== uuid);
        setBaterias(updatedBaterias);
        message.success('Bateria removida.');
    };

    const handleSameAddressChange = (e) => {
        const sameAddress = e.target.value === true;
        setUseSameAddress(sameAddress);

        if (sameAddress && siteData) {
            form.setFieldsValue({
                cepUsina: siteData.cep || '',
                ruaUsina: siteData.logradouro || '',
                bairroUsina: siteData.bairro || '',
                cidadeUsina: siteData.cidade || '',
                numeroUsina: siteData?.numero || '',
            });
        } else {
            form.setFieldsValue({
                cepUsina: '',
                ruaUsina: '',
                bairroUsina: '',
                cidadeUsina: '',
                numeroUsina: '',
            });
        }
    };

    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>
            <Form layout="vertical" form={form}>
                <h2 style={{ color: "#2957A4" }}>Bateria</h2>
                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="Nome do Gerente"
                            name="nomeGerente"
                            rules={[{ required: true, message: 'Informe o nome do gerente!' }]}
                        >
                            <Input placeholder="Nome do Gerente" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Email do Gerente"
                            name="emailGerente"
                            rules={[{ required: true, message: 'Informe o email!' }]}
                        >
                            <Input placeholder="Digite o Email" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item label="O Endereço é o mesmo do site?" name="mesmoEndereco">
                    <Radio.Group onChange={(e) => setUseSameAddress(e.target.value === 'sim')} defaultValue="sim">
                        <Radio value="sim">Sim</Radio>
                        <Radio value="nao">Não</Radio>
                    </Radio.Group>
                </Form.Item>
                {!useSameAddress && (
                    <Row gutter={12} align="bottom">
                        <Col span={4}>
                            <Form.Item label="CEP" name="cep" rules={[{ required: true, message: 'Informe o CEP!' }]}>
                                <Input placeholder="Digite o CEP" onChange={(e) => handleCepChange(e.target.value)} preserve={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Logradouro" name="logradouro" rules={[{ required: true, message: 'Informe o logradouro!' }]}>
                                <Input placeholder="Nome do Logradouro" preserve={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Bairro" name="bairro" rules={[{ required: true, message: 'Informe o bairro!' }]}>
                                <Input placeholder="Nome do Bairro" preserve={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Cidade" name="cidade" rules={[{ required: true, message: 'Informe a cidade!' }]}>
                                <Input placeholder="Nome da Cidade" preserve={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Estado" name="estado" rules={[{ required: true, message: 'Informe o estado!' }]}>
                                <Input placeholder="Nome do Estado" preserve={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item label="Nº" name="numero" rules={[{ required: true, message: 'Informe o número!' }]}>
                                <Input placeholder="Digite o Nº" onChange={(e) => handleNumeroChange(e.target.value)} preserve={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                {/* Botão para adicionar bateria */}
                <Button disabled type="primary" onClick={() => showModal()} style={{ width: '205px', marginBottom: '16px' }}>
                    + Adicionar Bateria
                </Button>

                {/* Lista de baterias adicionadas */}
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {baterias.map((bat) => (
                        <Tag
                            key={bat.uuid}
                            color="orange"
                            onClick={() => showModal(bat)}
                            style={{
                                cursor: 'pointer',
                                width: '205px',
                                height: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: '800',
                                fontSize: '16px',
                                textAlign: 'center',
                                position: 'relative',
                            }}
                        >
                            {bat.bateria.nome}
                            <Popconfirm
                                title="Tem certeza que deseja deletar esta bateria?"
                                onConfirm={() => handleDeleteBateria(bat.uuid)}
                                okText="Sim"
                                cancelText="Não"
                            >
                                <span
                                    style={{
                                        position: 'absolute',
                                        right: '5px',
                                        top: '5px',
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    x
                                </span>
                            </Popconfirm>
                        </Tag>
                    ))}
                </div>

                {/* Botões de controle */}
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', gap: 8 }}>
                            {currentStep > 0 && (
                                <Button onClick={onPrev} disabled={loading}>
                                    Voltar
                                </Button>
                            )}
                            {currentStep < totalSteps - 1 && (
                                <Button onClick={onNext} disabled={loading || !isBateriaSaved}>
                                    Próximo
                                </Button>
                            )}
                            {/* O botão de salvar do formulário principal pode ser mantido para outras informações, se necessário */}
                        </div>
                    </Col>
                </Row>
            </Form>

            {/* Modal para cadastro/edição de bateria */}
            <Modal
                title={currentBateria ? 'Editar Bateria' : 'Adicionar Bateria'}
                open={isModalVisible}
                onCancel={handleCloseModal}
                onOk={handleSaveBateria}
                width="50%"
            >
                <Form layout="vertical" form={modalForm} name="bateriaModal">
                    <Row gutter={12} align="bottom">
                        <Col span={12}>
                            <Form.Item
                                label="Nome da Bateria"
                                name="nomeBateria"
                                rules={[{ required: true, message: 'Informe o nome da bateria!' }]}
                            >
                                <Input placeholder="Nome da Bateria" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Fabricante"
                                name="fabricante"
                                rules={[{ required: true, message: 'Informe o fabricante!' }]}
                            >
                                <Input placeholder="Fabricante" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} align="bottom">
                        <Col span={12}>
                            <Form.Item
                                label="Modelo"
                                name="modelo"
                                rules={[{ required: true, message: 'Informe o modelo!' }]}
                            >
                                <Input placeholder="Modelo" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Tipo de Equipamento"
                                name="tipoEquipamento"
                                rules={[{ required: true, message: 'Informe o tipo de equipamento!' }]}
                            >
                                <Input placeholder="Tipo de Equipamento" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} align="bottom">
                        <Col span={12}>
                            <Form.Item
                                label="Capacidade (kWh)"
                                name="capacidade"
                                rules={[{ required: true, message: 'Informe a capacidade!' }]}
                            >
                                <Input placeholder="Capacidade da bateria" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Taxa de Descarga"
                                name="taxaDescarga"
                                rules={[{ required: true, message: 'Informe a taxa de descarga!' }]}
                            >
                                <Input placeholder="Taxa de Descarga" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} align="bottom">
                        <Col span={12}>
                            <Form.Item
                                label="Densidade Energética"
                                name="densidadeEnergetica"
                                rules={[{ required: true, message: 'Informe a densidade energética!' }]}
                            >
                                <Input placeholder="Densidade Energética" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Tensão"
                                name="tensao"
                                rules={[{ required: true, message: 'Informe a tensão!' }]}
                            >
                                <Input placeholder="Tensão" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} align="bottom">
                        <Col span={12}>
                            <Form.Item
                                label="Eficiência Energética"
                                name="eficienciaEnergetica"
                                rules={[{ required: true, message: 'Informe a eficiência energética!' }]}
                            >
                                <Input placeholder="Eficiência Energética" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Tempo de Recarga"
                                name="tempoRecarga"
                                rules={[{ required: true, message: 'Informe o tempo de recarga!' }]}
                            >
                                <Input placeholder="Tempo de Recarga" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} align="bottom">
                        <Col span={12}>
                            <Form.Item
                                label="Protocolo de Comunicação"
                                name="protocoloComunicacao"
                            >
                                <Select placeholder="Selecione o protocolo">
                                    <Option value="Protocolo 1">Protocolo 1</Option>
                                    <Option value="Protocolo 2">Protocolo 2</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Layout>
    );
};

export default Bateria;
