import React, { useEffect, useState, useMemo } from 'react';
import { Card, Slider, Switch, Row, Col, Select } from 'antd';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const { Option } = Select;

const SimulationGraphComponent = ({ simulationResponse }) => {
  const [selectedDay, setSelectedDay] = useState(0); // Dia selecionado para exibição
  const [maxPoints, setMaxPoints] = useState(1440); // Máximo de pontos (1 ponto por minuto)
  const [sliderDisabled, setSliderDisabled] = useState(false);

  

  

  // ** Função para dividir os dados por dia **
  const splitDataByDay = (responseData) => {
    let { Psol, Pdem, Pbat, Ebat, Pgrid } = responseData.data;
    const totalMinutes = Psol.length;
    const minutesPerDay = 1440; // 24h * 60min

    const numDays = Math.floor(totalMinutes / minutesPerDay);
    let dailyData = [];

    for (let i = 0; i < numDays; i++) {
      const start = i * minutesPerDay;
      const end = start + minutesPerDay;

      dailyData.push({
        Psol: Psol.slice(start, end),
        Pdem: Pdem.slice(start, end),
        Pbat: Pbat.slice(start, end),
        Ebat: Ebat.slice(start, end),
        Pgrid: Pgrid.slice(start, end),
      });
    }

    return dailyData;
  };

  // ** Função para processar os dados e reduzir o número de pontos **
  const transformSimulationData = (dayData, maxPoints) => {
    if (!dayData) return [];

    let { Psol, Pdem, Pbat, Ebat, Pgrid } = dayData;

    // Normalização de Ebat se necessário
    const maxEbat = Math.max(...Ebat);
    Ebat = Ebat.map(val => val / maxEbat * 50000);

    // Redução da quantidade de pontos para melhor performance
    const step = sliderDisabled ? 1 : Math.max(1, Math.floor(Psol.length / maxPoints));

    return Psol
      .map((_, index) => ({
        time: index % 120 === 0 ? `${Math.floor(index / 60)}h` : '',
        Psol: Psol[index] || 0,
        Pdem: Pdem[index] || 0,
        Pbat: Pbat[index] || 0,
        Ebat: Ebat[index] || 0,
        Pgrid: Pgrid[index] || 0,
      }))
      .filter((_, index) => index % step === 0);
  };

  // Separando os dados por dia
  const dailyData = useMemo(() => {
    if (simulationResponse && simulationResponse.data) {
      return splitDataByDay(simulationResponse);
    }
    return [];
  }, [simulationResponse]);
  
  // Pegando os dados do dia selecionado
  const chartData = useMemo(() => {
    if (dailyData.length > 0) {
      return transformSimulationData(dailyData[selectedDay], maxPoints);
    }
    return [];
  }, [dailyData, selectedDay, maxPoints]);

  return (
    <Card title="Resultado da Simulação" bordered={false}>
      <div style={{ marginBottom: 20 }}>
        <Row gutter={20} align={'middle'} style={{ marginLeft: '4px' }}>
          <Col>
            <span style={{ marginRight: 10 }}>Selecionar Dia:</span>
            <Select value={selectedDay} onChange={setSelectedDay} style={{ width: 100 }}>
              {dailyData.map((_, index) => (
                <Option key={index} value={index}>
                  Dia {index + 1}
                </Option>
              ))}
            </Select>
          </Col>
          <Col style={{ marginLeft: '30px' }}>
            <span style={{ marginRight: 10 }}>Quantidade de pontos:</span>
            <Slider
              min={100}
              max={1440}
              defaultValue={1440}
              onChange={setMaxPoints}
              disabled={sliderDisabled}
              style={{ width: 200 }}
            />
          </Col>
          <Col style={{ marginLeft: '30px' }}>
            <span style={{ marginRight: 10 }}>Desabilitar Slider:</span>
            <Switch checked={sliderDisabled} onChange={setSliderDisabled} />
          </Col>
        </Row>
      </div>

      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            label={{ value: 'Horas', position: 'insideBottomRight', offset: 0 }}
            interval={119} // Mostra rótulos a cada 2 horas (120 min)
          />
          <YAxis label={{ value: 'Potência (W)', angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Psol" stroke="#8884d8" name="Psol" dot={false} />
          <Line type="monotone" dataKey="Pdem" stroke="#ff0000" name="Pdem" dot={false} />
          <Line type="monotone" dataKey="Pbat" stroke="#00ff00" name="Pbat" dot={false} />
          <Line type="monotone" dataKey="Ebat" stroke="#ff7300" name="Ebat" dot={false} />
          <Line type="monotone" dataKey="Pgrid" stroke="#0000ff" name="Pgrid" dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default SimulationGraphComponent;
