import React, { useState } from 'react';
import { Layout, Form, Input, Row, Col, Button, message, Upload, Select } from 'antd';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const props = {
    name: 'file',
    multiple: true,
    action: '',
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};


const GridComponent = ({
    form,
    siteData,
    currentStep,
    totalSteps,
    onPrev,
    onNext,
    token,
}) => {
    const [loading, setLoading] = useState(false);
    const [isGridSaved, setIsGridSaved] = useState(false);


    const validateSiteData = () => {
        if (!siteData || Object.keys(siteData).length === 0) {
            message.error('As informações do site são obrigatórias para salvar o Grid.');
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        message.info("Endpoint em desenvolvimento, tenten ovamente mais tarde!")

        // try {
        //     if (!validateSiteData()) return;

        //     await form.validateFields();
        //     setLoading(true);

        //     const values = form.getFieldsValue();
        //     const payload = {
        //         uuid: uuidv4(),
        //         site: siteData, // Site associado
        //         numeroCliente: values.numeroCliente,
        //         transformador: values.transformador,
        //     };

        //     setIsGridSaved(true); // Atualiza estado para permitir avançar
        //     await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/grids`, payload, {
        //         headers: { Authorization: `Bearer ${token}` },
        //     });

        //     message.success('Grid salvo com sucesso!');
        // } catch (error) {
        //     message.error(error.message || 'Erro ao salvar o Grid.');
        // } finally {
        //     setLoading(false);
        // }
    };

    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>
            <Form layout="vertical" form={form}>
                <h2 style={{ color: "#2957A4" }}>Grid</h2>
                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="Código do Cliente"
                            name="numeroCliente"
                            rules={[{ required: true, message: 'Informe o código do cliente!' }]}
                        >
                            <Input placeholder="Digite o Nº" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Nome"
                            name="nome"
                            rules={[{ required: true, message: 'Informe o nome do cliente!' }]}
                        >
                            <Input placeholder="Digite o nome" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="CPF"
                            name="cpf"
                            rules={[{ required: true, message: 'Informe o cpf do cliente!' }]}
                        >
                            <Input placeholder="Digite o cpf" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <h3 style={{ color: "#2957A4" }}>Upload de dados</h3>
                </Row>
                <Row  style={{ marginBottom: '1%' }} gutter={4}> 
                    <Select
                        placeholder="Selecione a concessionária"
                        style={{ width: 272 }}
                        options={[
                            { value: 'jack', label: 'Jack' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                        ]}
                        allowClear
                        disabled
                    />
                </Row>
                <Row>
                    <Col style={{ width: '50%' }}>
                        <Dragger {...props} disabled>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Clique ou arraste o arquivo para esta área para fazer upload
                            </p>
                        </Dragger>
                        <p style={{ marginTop: '8px', fontSize: '14px', color: '#11111146' }}>
                            * Upload do documento PDF (descrição)
                        </p>
                    </Col>
                </Row>
                <Row justify="start" style={{ marginTop: '5%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', gap: 8 }}>
                        {currentStep > 0 && (
                            <Button onClick={onPrev} disabled={loading}>
                                Voltar
                            </Button>
                        )}
                        {currentStep < totalSteps && (
                            <Button onClick={onNext} disabled={loading || !isGridSaved}>
                                Próximo
                            </Button>
                        )}
                        <Button type="primary" onClick={handleSave} loading={loading}>
                            Salvar
                        </Button>
                    </div>
                </Row>

            </Form>
        </Layout>
    );
};

export default GridComponent;
