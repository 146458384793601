import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Layout, Menu, message, Dropdown, Typography, Avatar, Space, Badge, Modal, Form, Input, List, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, BellOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';
import axios from 'axios';

const url_base = process.env.REACT_APP_URL_BACKEND;

const { Header } = Layout;
const { Text } = Typography;
const { Option } = Select;

const AppHeader = () => {
    const token = localStorage.getItem('token');
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [empName, setEmpName] = useState(localStorage.getItem('companyName') || 'Empresa Cliente'); 
    const [empId, setEmpId] = useState(localStorage.getItem('companyId') || null);
    const [companies, setCompanies] = useState([]);
    const [notifications] = useState([
        // 'Nova mensagem de usuário',
        // 'Atualização do sistema disponível',
        // 'Nova análise concluída',
        // 'Erro no servidor identificado',
        // 'Nova configuração salva',
    ]);
    const [isHovered, setIsHovered] = useState(false);
    const roles = user?.role.split(',').map(role => role.trim());
    const isAdmin = roles?.includes("ROLE_ADMIN") || roles?.includes("ROLE_DEV");

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get(`${url_base}/api/empresa-clientes`, {
                headers: { Authorization: `Bearer ${token}` },
                accept: '*/*'
            });
            setCompanies(response.data);
        } catch (error) {
            message.error('Erro ao carregar empresas cadastradas.');
        }
    };

    const handleEditClick = () => {
        fetchCompanies();
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleSave = async (values) => {
        try {
            
            let selectedCompany = companies.find(c => c.id === values.companyId);
            if (!selectedCompany && values.newCompanyName) {
                // Criar nova empresa
                const response = await axios.post(`${url_base}/api/empresa-clientes`, {
                    nome: values.newCompanyName,
                    cnpj: values.newCompanyCnpj,
                }, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                selectedCompany = response.data;
            }

            if (selectedCompany) {
                setEmpName(selectedCompany.nome);
                setEmpId(selectedCompany.id);
                localStorage.setItem('companyName', selectedCompany.nome);
                localStorage.setItem('companyId', selectedCompany.id);
                message.success('Empresa atualizada com sucesso!');
            }
            setIsModalVisible(false);
            form.resetFields();
        } catch (error) {
            message.error('Erro ao atualizar a empresa.');
        }
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };
    
    const userMenu = (
        <Menu>
            <Menu.Item key="account" icon={<UserOutlined />} onClick={() => navigate('/gerenciar-conta')}>
                Conta
            </Menu.Item>
            {isAdmin && (
                <Menu.Item key="manage-users" icon={<SettingOutlined />} onClick={() => navigate('/gerenciar-usuarios')}>
                    Gerenciar Usuários
                </Menu.Item>
            )}
            <Menu.Item key="logout" icon={<SettingOutlined />} onClick={handleLogout}>
                Sair
            </Menu.Item>
        </Menu>
    );

    // Obtém as iniciais ou as duas primeiras letras do nome da empresa
    const getAvatarText = (name) => {
        if (!name) return '';
        return name.split(' ').map(word => word[0]).join('').substring(0, 2).toUpperCase();
    };

    // Dropdown de notificações
    const notificationsMenu = (
        <Menu style={{ padding: '20px' }}>
            {notifications.length > 0 ? <List
                dataSource={notifications}
                renderItem={(item, index) => (
                    <List.Item key={index}>{item}</List.Item>
                )}
            /> :
                'Sem Notificações'
            }
        </Menu>
    );

    return (
        <>
            <Header className="header" style={{ backgroundColor: '#fff', paddingRight: '24px', paddingLeft: '24px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Avatar size="large" style={{ backgroundColor: '#7265e6' }}>
                            {getAvatarText(empName)}
                        </Avatar>
                        <Text style={{ fontSize: '14px', color: '#888' }}>{empName}</Text>
                        <div
                            style={{
                                display: 'inline-block',
                                opacity: isHovered ? 1 : 0.2,
                                transition: 'opacity 0.3s',
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <EditOutlined
                                style={{ cursor: 'pointer', color: '#888', fontSize: '18px', margin: '0 10px' }}
                                onClick={handleEditClick}
                            />
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '50px', margin: '0 20px' }}>
                        {/* Dropdown de notificações */}
                        <Dropdown overlay={notificationsMenu} trigger={['click']}>
                            <Badge count={notifications.length} offset={[10, 0]}>
                                <BellOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
                            </Badge>
                        </Dropdown>

                        <Dropdown overlay={userMenu} trigger={['click']}>
                            <Space>
                                <Avatar size="large" icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Text>{user?.username}</Text>
                                    <Text style={{ fontSize: '12px', color: '#888' }}>{
                                        roles.includes('ROLE_ADMIN') ? "Administrador" : roles.includes('ROLE_DEV') ? "Desenvolvedor" : "Cliente"
                                    }</Text>
                                </div>
                            </Space>
                        </Dropdown>
                    </div>
                </div>
            </Header>

            {/* Modal para editar o nome da empresa */}
            <Modal title="Selecionar ou Cadastrar Empresa" visible={isModalVisible} onCancel={handleCancel} onOk={() => form.submit()} okText="Salvar" cancelText="Cancelar">
                <Form form={form} layout="vertical" onFinish={handleSave}>
                    <Form.Item label="Selecionar Empresa" name="companyId">
                        <Select placeholder="Escolha uma empresa">
                            {companies.map(company => (
                                <Option key={company.id} value={company.id}>{company.nome} | {company.cnpj}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Text strong>Ou cadastre uma nova:</Text>
                    <Form.Item label="Nome da Empresa" name="newCompanyName">
                        <Input placeholder="Digite o nome da empresa" />
                    </Form.Item>
                    <Form.Item label="CNPJ" name="newCompanyCnpj">
                        <Input placeholder="Digite o CNPJ" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AppHeader;