import React, { useState } from 'react';
import axios from 'axios';
import { Layout, Button, Form, Input, Row, Col } from 'antd';
import FooterComponent from '../components/Footer/FooterComponent';
import AppHeader from '../components/Header/AppHeader';
import SideMenu from '../components/SideMenu/SideMenu';
import SimulationGraphComponent from '../components/Graph/SimulationGraph';
import GraphExampleComponent from '../components/Graph/GraphExampleComponent';

const { Content } = Layout;
const token = localStorage.getItem('token');

const AnalisePage = () => {
    const [form] = Form.useForm(); // Gerencia o formulário
    const [simulationResponse, setSimulationResponse] = useState(null);
    const [loading, setLoading] = useState(false); // Estado para controlar o loading

    const handleFinish = (values) => {
        // Quando o formulário for submetido, faz a chamada à API
        setLoading(true); // Ativa o estado de loading

        const { dias, timestep, usuarios } = values; // Obtém os valores dos inputs

        // Faz a chamada à API
        axios
            .post(
                `${process.env.REACT_APP_URL_BACKEND}/api/python/simulacao?sampleSize=${usuarios}`,
                {}, // Corpo da requisição, vazio no caso
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                // Parseia o campo `data` recebido
                const parsedData = JSON.parse(response.data.data);
                setSimulationResponse(parsedData);
            })
            .catch((error) => {
                console.error('Erro ao buscar os dados da simulação:', error);
            })
            .finally(() => {
                setLoading(false); // Desativa o estado de loading
            });
    };

    return (
        <Layout style={{ height: '100vh' }}>
            <SideMenu style={{ position: 'fixed', zIndex: 1 }} />
            <Layout>
                <AppHeader style={{ position: 'fixed', width: '100%', zIndex: 1, top: 0 }} />
                <Content
                    style={{
                        overflowY: 'auto',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <div style={{ flex: 1, backgroundColor: '#FFF', padding: '20px', borderRadius: '8px' }}>
                            <h1>Simulação</h1>

                            {/* Formulário */}
                            <div>
                                <Form form={form} layout="vertical" onFinish={handleFinish}>
                                    <Row gutter={12} align="bottom">
                                        {/* <Col span={3}>
                                            <Form.Item
                                                label="Dias de Simulação"
                                                name="dias"
                                                rules={[{ required: true, message: 'Insira o número de dias' }]}
                                            >
                                                <Input type="number" placeholder="Insira o número de dias" />
                                            </Form.Item>
                                        </Col> */}
                                        {/* <Col span={5}>
                                            <Form.Item
                                                label="Intervalo de Tempo (min)"
                                                name="timestep"
                                                rules={[{ required: true, message: 'Insira o intervalo de tempo' }]}
                                            >
                                                <Input type="number" placeholder="Insira o intervalo de tempo em minutos" />
                                            </Form.Item>
                                        </Col> */}
                                        <Col span={3}>
                                            <Form.Item
                                                label="Amostra de Usuários"
                                                name="usuarios"
                                                rules={[{ required: true, message: 'Insira o tamanho da amostra' }]}
                                            >
                                                <Input type="number" placeholder="Insira o tamanho da amostra" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" loading={loading}>
                                                    Iniciar Simulação
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>

                            {/* Exibição do Gráfico */}
                            <div style={{ minHeight: 500 }}>
                                {simulationResponse ? (
                                    <SimulationGraphComponent simulationResponse={simulationResponse} />
                                ) : (
                                    <p>{loading ? 'Carregando...' : 'Insira os parâmetros e clique em Iniciar Simulação.'}</p>
                                )}
                            </div>
                            <div>
                                <h1>Análise de Consumo</h1>

                                <GraphExampleComponent />
                            </div>
                        </div>



                    </div>

                    <FooterComponent />
                </Content>
            </Layout>
        </Layout>
    );
};

export default AnalisePage;
