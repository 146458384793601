import React from 'react';
import { BaseEdge, getBezierPath, EdgeLabelRenderer, Position } from '@xyflow/react';

/**
 * Retorna o centro do bounding box de um nó no DOM.
 */
function getNodeCenter(nodeElement) {
  const { x, y, width, height } = nodeElement.getBoundingClientRect();
  return {
    cx: x + width / 2,
    cy: y + height / 2,
  };
}

/**
 * Aresta "flutuante" que calcula dinamicamente a posição de origem e destino.
 */
function FloatingEdge({
  id,
  source,       // id do nó de origem
  target,       // id do nó de destino
  markerEnd,
  style,
  data,
}) {
  // Busca os elementos DOM dos nós de origem e destino
  const sourceNode = document.querySelector(`[data-id="${source}"]`);
  const targetNode = document.querySelector(`[data-id="${target}"]`);

  // Se algum nó ainda não estiver montado no DOM, não desenha nada
  if (!sourceNode || !targetNode) {
    return null;
  }

  // Calcula o centro de cada nó
  const { cx: sx, cy: sy } = getNodeCenter(sourceNode);
  const { cx: tx, cy: ty } = getNodeCenter(targetNode);

  // Opcional: definir as posições “falsas” (left/right/top/bottom) 
  // só para orientar a curva. Exemplo: se sx < tx, a aresta sai pela direita do source.
  const sourcePosition = sx <= tx ? Position.Right : Position.Left;
  const targetPosition = sx <= tx ? Position.Left : Position.Right;

  // Gera o path (Bezier ou SmoothStep) entre (sx, sy) e (tx, ty)
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
    sourcePosition,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={style}
      />
      
      {data?.label && (
        <EdgeLabelRenderer>
          <div
            style={{
              transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
            }}
            className="nodrag nopan"
          >
            {data.label}
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

export default FloatingEdge;
