import React, { useCallback } from 'react';
import {
    ReactFlow,
    Background,
    Controls,
    useNodesState,
    useEdgesState,
    addEdge,
    MarkerType,
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';

import { initialNodes } from './graphComponets/initialElements'
import FloatingEdge from './graphComponets/FloatingEdge';

const initialEdges = [
    {
        id: 'grid-solar',
        source: 'grid',
        target: 'solar',
        type: 'smoothstep',
        animated: false,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#FFA726' },
        style: { stroke: '#FFA726', strokeWidth: 2 },
    },
    {
        id: 'grid-bat',
        source: 'grid',
        target: 'battery',
        type: 'smoothstep',
        animated: false,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#FFA726' },
        style: { stroke: '#FFA726', strokeWidth: 2 },
    },
    {
        id: 'grid-load',
        source: 'grid',
        target: 'load',
        type: 'smoothstep',
        animated: false,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#FFA726' },
        style: { stroke: '#FFA726', strokeWidth: 2 },
    },
    {
        id: 'grid-car',
        source: 'grid',
        target: 'car',
        type: 'smoothstep',
        animated: true,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#FFA726' },
        style: { stroke: '#FFA726', strokeWidth: 2 },
    },
    {
        id: 'load-bat',
        source: 'load',
        target: 'battery',
        type: 'smoothstep',
        animated: false,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#2957A4' },
        style: { stroke: '#2957A4', strokeWidth: 2 },
    },
    {
        id: 'load-car',
        source: 'load',
        target: 'car',
        type: 'smoothstep',
        animated: false,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#2957A4' },
        style: { stroke: '#2957A4', strokeWidth: 2 },
    },
    {
        id: 'load-solar',
        source: 'load',
        target: 'solar',
        type: 'smoothstep',
        animated: false,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#2957A4' },
        style: { stroke: '#2957A4', strokeWidth: 2 },
    },
    {
        id: 'car-solar',
        source: 'car',
        target: 'solar',
        type: 'smoothstep',
        animated: false,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#B91293' },
        style: { stroke: '#B91293', strokeWidth: 2 },
    },
    {
        id: 'car-solar',
        source: 'car',
        target: 'solar',
        type: 'smoothstep',
        animated: false,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#B91293' },
        style: { stroke: '#B91293', strokeWidth: 2 },
    },
    {
        id: 'bat-solar',
        source: 'battery',
        target: 'solar',
        type: 'smoothstep',
        animated: false,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#6CD84C' },
        style: { stroke: '#6CD84C', strokeWidth: 2 },
    },
    {
        id: 'bat-car',
        source: 'battery',
        target: 'car',
        type: 'smoothstep',
        animated: false,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#6CD84C' },
        style: { stroke: '#6CD84C', strokeWidth: 2 },
    },


];

const edgeTypes = {
    floating: FloatingEdge,
};


const GraphExampleComponent = () => {
    const [nodes, _, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((els) => addEdge(params, els)),
        [setEdges]
    );

    return (
        <div
            style={{
                width: '100%',
                height: '60vh',
                overflow: 'hidden',
                padding: '10px',
                borderRadius: '8px',
                backgroundColor: "#F7F9FB"
            }}
        >
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                style={{ backgroundColor: "white" }}
                fitView
                edgeTypes={edgeTypes}
            >
                <Background />
                <Controls />
            </ReactFlow>
        </div>
    );
};

export default GraphExampleComponent;
