import React, { useEffect, useState } from 'react';
import { Layout, Form, Input, Row, Col, message, Button, Radio } from 'antd';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const UsinaTermeletrica = ({ 
    form,
    siteData,
    currentStep,
    totalSteps,
    onPrev,
    onNext,
    token,
    onSave,
    handleCepChange
 }) => {
    console.log('termo',  currentStep)
    const [loading, setLoading] = useState(false);
    const [isUsinaTermSaved, setIsUsinaTermSaved] = useState(false); 
    const [useSameAddress, setUseSameAddress] = useState(true);

    const handleSaveForm = () => form.getFieldsValue(); // Captura os valores do formulário

    useEffect(() => {
        if (onSave) {
            onSave(handleSaveForm); // Define a função onSave
        }
    }, [onSave]);

    const validateSiteData = () => {
        if (!siteData || Object.keys(siteData).length === 0) {
            message.error('As informações do site são obrigatórias para salvar a Usina Termelétrica.');
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        // message.info("Endpoint em desenvolvimento, tente novamente mais tarde!")
        
        try {
            if (!validateSiteData()) return;

            await form.validateFields();
            setLoading(true);

            const values = form.getFieldsValue();
            const payload = {
                uuid: uuidv4(),
                site: siteData, // Site associado
                // idTermo: values.idTermo,
                // transformadorTermo: values.transformadorTermo,
                bairro: values.bairroUsina,
                cep: values.cepUsina,
                cidade: values.cidadeUsina,
                emailResponsavel: values.emailGerente,
                estado: values.estadoUsina || siteData.estado,
                kilowattPico: parseFloat(values.kwpTotal) || 0,
                latitude: parseFloat(values.latitude) || 0,
                longitude: parseFloat(values.longitude) || 0,
                logradouro: values.ruaUsina,
                mesmoEnderecoSite: useSameAddress,
                nome: values.nomeGerente,
                numero: parseInt(values.numeroUsina, 10) || 0,
                numeroModulos: parseInt(values.modulosUsina, 10) || 0,
                pessoaResponsavel: values.nomeGerente,
                protocoloComunicacao: values.protocoloComunicacao || 'Não informado',
                site: siteData, // Informações do site
                uuid: uuidv4(),
            };

            setIsUsinaTermSaved(true); // Atualiza estado para permitir avançar
            await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/usina-termeletricas`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            });

            message.success('Usina Termelétrica salva com sucesso!');
        } catch (error) {
            message.error(error.message || 'Erro ao salvar a Usina Termelétrica.');
        } finally {
            setLoading(false);
        }
    };


    const handleSameAddressChange = (e) => {
        const sameAddress = e.target.value === true;
        setUseSameAddress(sameAddress);

        if (sameAddress && siteData) {
            form.setFieldsValue({
                cepUsina: siteData.cep || '',
                ruaUsina: siteData.logradouro || '',
                bairroUsina: siteData.bairro || '',
                cidadeUsina: siteData.cidade || '',
                numeroUsina: siteData?.numero || '',
            });
        } else {
            form.setFieldsValue({
                cepUsina: '',
                ruaUsina: '',
                bairroUsina: '',
                cidadeUsina: '',
                numeroUsina: '',
            });
        }
    };

    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>
            <Form layout="vertical" form={form}>
                <h2 style={{color: "#2957A4"}}>Usina Termelétrica</h2>

                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="Identificador"
                            name="idEolica"
                            rules={[{ required: true, message: 'Informe identificador da usina!' }]}
                        >
                            <Input placeholder="Digite o Nº" disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Transformador"
                            name="transformadorEolica"
                            rules={[{ required: true, message: 'Informe o transformador!' }]}
                        >
                            <Input placeholder="Informe o transformador" disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="Nome do Gerente"
                            name="nomeGerente"
                            rules={[{ required: true, message: 'Informe o nome do gerente!' }]}
                        >
                            <Input placeholder="Nome do Gerente" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Email do Gerente"
                            name="emailGerente"
                            rules={[{ required: true, message: 'Informe o email!' }]}
                        >
                            <Input placeholder="Digite o Email" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Telefone ou Celular"
                            name="telefoneGerente"
                            rules={[{ required: true, message: 'Informe o telefone!' }]}
                        >
                            <Input placeholder="Digite o Número" />
                        </Form.Item>
                    </Col>
                </Row>
                <h2 style={{ color: '#2957A4' }}>Endereço da Usina</h2>
                <Row gutter={12} align="bottom">
                    <Col span={24}>
                        <Form.Item label="O Endereço é o mesmo do site?" name="sameAddress">
                            <Radio.Group onChange={handleSameAddressChange} defaultValue={true}>
                                <Radio value={true}>Sim</Radio>
                                <Radio value={false}>Não</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="CEP"
                            name="cepUsina"
                            rules={[{ required: true, message: 'Informe o CEP!' }]}
                        >
                            <Input
                                placeholder="Digite o CEP"
                                onChange={(e) => handleCepChange(e.target.value)}
                                disabled={useSameAddress}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Rua"
                            name="ruaUsina"
                            rules={[{ required: true, message: 'Informe a rua!' }]}
                        >
                            <Input placeholder="Nome da Rua" disabled={useSameAddress} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Bairro"
                            name="bairroUsina"
                            rules={[{ required: true, message: 'Informe o bairro!' }]}
                        >
                            <Input placeholder="Nome do Bairro" disabled={useSameAddress} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cidade"
                            name="cidadeUsina"
                            rules={[{ required: true, message: 'Informe a cidade!' }]}
                        >
                            <Input placeholder="Nome da Cidade" disabled={useSameAddress} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Nº"
                            name="numeroUsina"
                            rules={[{ required: true, message: 'Informe o número!' }]}
                        >
                            <Input placeholder="Digite o Nº" disabled={useSameAddress} />
                        </Form.Item>
                    </Col>
                </Row>

                <h2 style={{ color: '#2957A4' }}>Informações Gerais</h2>
                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="kWp Total"
                            name="kwpTotal"
                            rules={[{ required: true, message: 'Informe a potência total!' }]}
                        >
                            <Input placeholder="kWp Total" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Potência Outorgada"
                            name="potenciaOutorga"
                            rules={[{ required: true, message: 'Informe a potência de !' }]}
                        >
                            <Input placeholder="Potência Outorgada" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Quantidade de Módulos"
                            name="modulos"
                            rules={[{ required: true, message: 'Informe a quantidade de módulos!' }]}
                        >
                            <Input placeholder="Quantidade de módulos" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Potência do Módulo (W)"
                            name="potenciaModulo"
                            rules={[{ required: true, message: 'Informe a potência do módulo!' }]}
                        >
                            <Input placeholder="Potência do Módulo" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Protocolo de Comunicação"
                            name="protocoloComunicacaoModulo"
                            rules={[{ required: true, message: 'Informe o protocolo de comunicação do módulo!' }]}
                        >
                            <Input placeholder="Protocolo de Comunicação" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', gap: 8 }}>
                            {currentStep > 0 && (
                                <Button onClick={onPrev} disabled={loading}>
                                    Voltar
                                </Button>
                            )}
                            {currentStep < totalSteps - 1 && (
                                <Button onClick={onNext} disabled={loading || !isUsinaTermSaved}>
                                    Próximo
                                </Button>
                            )}
                            <Button type="primary" onClick={handleSave} loading={loading}>
                                Salvar
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Layout>
    );
};

export default UsinaTermeletrica;
