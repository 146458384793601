import React, { useState } from 'react';
import { Layout, Form, Input, Select, Row, Col, Button, message } from 'antd';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import * as DataServices from '../../dataservices/Services'
const { Option } = Select;

const Site = ({
    form,
    sitesExistentes,
    selectedSite,
    handleSiteChange,
    handleCepChange,
    handleNumeroChange,
    currentStep,
    totalSteps,
    onPrev,
    onNext,
    onSave,
    token,
}) => {
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [siteSalvo, setSiteSalvo] = useState(false);
    
    const fetchCompanies = async () => {
        try {
            const data = await DataServices.fetchCompanies(); 
            setCompanies(data);
        } catch (error) {
            message.error('Erro ao carregar empresas cadastradas.');
        }
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            await form.validateFields();
            const values = form.getFieldsValue();
    
            await fetchCompanies();
            let selectedCompany = companies.find(c => c.cnpj === values.responsavel);
    
            if (!selectedCompany) {
                if (!values.nome || !values.responsavel) {
                    message.error('Erro: Informe os dados da empresa antes de continuar.');
                    setLoading(false);
                    return;
                }
    
                try {
                    // Criar nova empresa
                    const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/empresa-clientes`, {
                        nome: values.nome,
                        cnpj: values.responsavel,
                    }, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    selectedCompany = response.data;
                    message.success('Empresa cadastrada com sucesso!');
                } catch (error) {
                    message.error('Erro ao cadastrar ou selecionar a empresa.');
                    setLoading(false);
                    return;
                }
            }
    
            // Criar payload do site
            const payload = {
                uuid: uuidv4(),
                nome: values.nome,
                pessoaResponsavel: values.responsavel,
                celular: values.telefone,
                cargo: values.cargo,
                cep: values.cep,
                emailResponsavel: values.email,
                numero: parseInt(values.numero, 10),
                logradouro: values.rua,
                bairro: values.bairro,
                cidade: values.cidade,
                estado: values.estado,
                latitude: 0,
                longitude: 0,
                telefone: values.telefone,
                status: values.status,
                metadata: JSON.stringify({}),
                empresaCliente: selectedCompany
            };
    
            await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/sites`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            });

            message.success('Site salvo com sucesso!');
            setSiteSalvo(true); // Atualiza o estado indicando que o site foi salvo
            onSave(payload);
        } catch (error) {
            message.error(error.message || 'Erro ao salvar o site.');
        } finally {
            setLoading(false);
        }
    };

    
    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>
            <Form layout="vertical" form={form}>
                <h2 style={{ color: "#2957A4" }}>Site</h2>

                <Row gutter={12} align="bottom">
                    <Col span={8}>
                        <Form.Item label="Selecione Site Existente">
                            <Select
                                placeholder="Escolha um site existente"
                                value={selectedSite || " "}
                                onChange={handleSiteChange}
                                allowClear
                            >
                                {sitesExistentes.map(site => (
                                    <Option key={site.id} value={site.id}>
                                        {site.nome}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="Nome do Site"
                            name="nome"
                            rules={[{ required: true, message: 'Informe o nome do projeto!' }]}
                        >
                            <Input placeholder="Nome do Projeto" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="CNPJ do Responsável"
                            name="responsavel"
                            rules={[{ required: true, message: 'Informe o CNPJ do Responsável!' }]}
                        >
                            <Input placeholder="Digite o Nome" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Telefone ou Celular"
                            name="telefone"
                            rules={[{ required: true, message: 'Informe o telefone!' }]}
                        >
                            <Input placeholder="Digite o Número" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Informe o email!' }]}
                        >
                            <Input placeholder="Digite o Número" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cargo do Responsável"
                            name="cargo"
                            rules={[{ required: true, message: 'Escolha o cargo!' }]}
                        >
                            <Select placeholder="Escolha o Cargo">
                                <Option value="Gerente">Gerente</Option>
                                <Option value="Supervisor">Supervisor</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <h2 style={{ color: "#2957A4" }}>Endereço do Site</h2>

                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="CEP"
                            name="cep"
                            rules={[{ required: true, message: 'Informe o CEP!' }]}
                        >
                            <Input placeholder="Digite o CEP" onChange={(e) => handleCepChange(e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Rua"
                            name="rua"
                            rules={[{ required: true, message: 'Informe a rua!' }]}
                        >
                            <Input placeholder="Nome da Rua" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Bairro"
                            name="bairro"
                            rules={[{ required: true, message: 'Informe o bairro!' }]}
                        >
                            <Input placeholder="Nome do Bairro" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cidade"
                            name="cidade"
                            rules={[{ required: true, message: 'Informe a cidade!' }]}
                        >
                            <Input placeholder="Nome da Cidade" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Complemento" name="complemento">
                            <Input placeholder="Digite o Complemento" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Nº"
                            name="numero"
                            rules={[{ required: true, message: 'Informe o número!' }]}
                        >
                            <Input placeholder="Digite o Nº" onChange={(e) => handleNumeroChange(e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Status do Site"
                            name="status"
                            
                            rules={[{ required: true, message: 'Selecione o atual status do site.' }]}
                        >
                            <Select placeholder="Selecione o status">
                                <Option value="Conectado">Conectado</Option>
                                <Option value="Desconectado">Desconectado</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', gap: 8 }}>
                            {/* O botão "Voltar" só aparece se não for o primeiro passo */}
                            {currentStep > 0 && (
                                <Button onClick={onPrev} disabled={loading}>
                                    Voltar
                                </Button>
                            )}
                            {/* Botão "Próximo" só fica ativo se um site for salvo ou selecionado */}
                            {currentStep < totalSteps - 1 && (
                                <Button
                                    onClick={onNext}
                                    disabled={loading || (!siteSalvo && !selectedSite)}
                                >
                                    Próximo
                                </Button>
                            )}
                            <Button type="primary" onClick={handleSave} loading={loading}>
                                Salvar
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Layout>
    );
};

export default Site;
